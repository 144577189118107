import { gql } from "@apollo/client";

export const GET_HOME = gql`
  query GetHome {
    pages(where: {title: "Home"}) {
      nodes {
        id
        slug
        status
        date
        template {
          templateName
        }
        title
        content
        homepageacf {
          fieldGroupName
          video
        }
      },
      edges {
        node {
          seo {
            title
            focuskw
            metaDesc
          }
        }
      }
    }
  }
`;

export default GET_HOME;